import React from "react";
import styled from "styled-components";

import {
  ApplicationsBottomImage,
  ApplicationsFlow1,
  ApplicationsFlow2,
  ApplicationsFlow3,
  ApplicationsFlow4,
  ApplicationsFlow5,
  ApplicationsFlow6,
  ApplicationsFlow7,
  ApplicationsFlow8,
} from "assets";
import { ImageCard, SectionTitle } from "../Common";

const ApplicationsContainer = styled.div`
  width: 100%;
  background: #fcfcfc;
  margin-top: -1px !important;
  ::after {
    content: url(${ApplicationsBottomImage});
    display: block;
    background-image: linear-gradient(0deg, white 50%, #fcfcfc 50%);
  }
`;

const InstaJobTextContainer = styled.div`
  font-size: 14px;
  padding-left: 3rem;
  line-height: 3vw;
  @media (max-width: 700px) {
    font-size: 9px;
    margin-bottom: 1rem;
    padding-left: 1rem !important;
  }
`;

const ApplicationsRowContainer = styled.div`
  display: flex;
  margin: 2.5rem 4rem;
  :nth-child(odd) {
    justify-content: flex-start;
  }
  :nth-child(even) {
    justify-content: flex-end;
  }
  @media (max-width: 700px) {
    justify-content: flex-start;
    flex-direction: column;
    margin: 0;
  }
`;

const ApplicationsRow = () => {
  return (
    <>
      <ApplicationsRowContainer>
        <ImageCard
          heading={Texts[`Head1`]}
          message={Texts[`Text1`]}
          imageSource={ApplicationsFlow1}
        />
        <ImageCard
          heading={Texts[`Head2`]}
          message={Texts[`Text2`]}
          imageSource={ApplicationsFlow2}
        />
      </ApplicationsRowContainer>
      <ApplicationsRowContainer>
        <ImageCard
          heading={Texts[`Head3`]}
          message={Texts[`Text3`]}
          imageSource={ApplicationsFlow3}
        />
        <ImageCard
          heading={Texts[`Head4`]}
          message={Texts[`Text4`]}
          imageSource={ApplicationsFlow4}
        />
      </ApplicationsRowContainer>
      <ApplicationsRowContainer>
        <ImageCard
          heading={Texts[`Head5`]}
          message={Texts[`Text5`]}
          imageSource={ApplicationsFlow5}
        />
        <ImageCard
          heading={Texts[`Head6`]}
          message={Texts[`Text6`]}
          imageSource={ApplicationsFlow6}
        />
      </ApplicationsRowContainer>
      <ApplicationsRowContainer>
        <ImageCard
          heading={Texts[`Head7`]}
          message={Texts[`Text7`]}
          imageSource={ApplicationsFlow7}
        />
        <ImageCard
          heading={Texts[`Head8`]}
          message={Texts[`Text8`]}
          imageSource={ApplicationsFlow8}
        />
      </ApplicationsRowContainer>
    </>
  );
};

const Applications = () => {
  return (
    <ApplicationsContainer>
      <SectionTitle marginBottom={false}>
        {"How it benefits you"}
      </SectionTitle>
      <InstaJobTextContainer>
        {
          'Apply4U does one very simple thing. It removes the drudgery from your job search experience.'
        }
      </InstaJobTextContainer>
      <ApplicationsRow />
    </ApplicationsContainer>
  );
};

const Texts: any = {
  Head1: `Team work`,
  Text1: `Apply4U is your new, dedicated, job applications team.`,
  Head2: `Companies contact directly`,
  Text2: `Companies receive your resume, application form and contact details and contact you directly.`,
  Head3: `Removes drudgery`,
  Text3: `Apply4U saves you 40+ hours per month of tedious application submission process.`,
  Head4: `Negotiation leverage`,
  Text4: `Because Apply4U submission volume you are bound to get multiple offers leading to better salary negotiation`,
  Head5: `Your daily updates`,
  Text5: `Each day, you’ll receive an email confirming applications that were sent and received. Along with any responses from those companies.`,
  Head6: `Ignorance is bliss`,
  Text6: `Typically it takes numerous applications to lead to few in person interviews. Our customers are happy to not have to deal with the reject emails and only focus on the ones that really matter`,
  Head7: `Application strategy`,
  Text7: `You are in complete control of your application strategy. Apply4U provides you the necessary scale for execution.`,
  Head8: `Good reviews`,
  Text8: `High conversion rate and happy customers. Apply4U reduces job search time by 50% and increase your potential for multiple offers and better negotiation leverage`,
};

export default Applications;
