import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const MembersCardContainer = styled.div`
  margin: 0 2%;
  width: 50%;
  ${isMobile &&
  `
    margin: 2%;
    width: 100%;
  `}
`;

const CommentsContainer = styled.div`
  padding-top: 24px;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    border: 12px solid transparent;
    border-bottom: 13px solid #f8f8f8;
    top: 0px;
    left: 4.5vw;
    ${isMobile &&
    `
            left: 47%;
        `}
  }
`;

const CommentBoxContainer = styled.div`
  font-family: "Poppins";
  min-width: 30%;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  padding: 3%;
  font-size: 1.3vw;
  ${isMobile &&
  `
        font-size: 4vw;
    `}
`;

const Comment = styled.div`
  font-family: Poppins;
  .heading {
    margin-bottom: 1rem;
  }
`;

const Name = styled.div`
  font-weight: 600;
  margin-top: 10%;
  font-size: 2vw;
  ${isMobile &&
  `
        font-size: 5vw;
    `}
`;

const ImageContainer = styled.div`
  img {
    border-radius: 50%;
    width: 10.5rem;
  }
  ${isMobile &&
  `
    .image {
        display: block;
        height: 24vw;
        margin-left: auto;
        margin-right: auto;
    }
    img {
      width: 6.5rem;
    }
  `}
`;

const Designation = styled.div``;

interface CommentBoxInterface {
  imageSource: string;
  heading: string;
  message: string;
  name: string;
  designation: string;
}

const CommentBox = ({
  imageSource,
  heading,
  message,
  name,
  designation,
}: CommentBoxInterface) => {
  return (
    <MembersCardContainer className="pt-2">
      <ImageContainer>
        <img className={"image"} src={imageSource} />
      </ImageContainer>
      <CommentsContainer>
        <CommentBoxContainer>
          <Comment>
            <div className={"heading"}>{heading}</div>
            {message}
          </Comment>
          <Name>{name}</Name>
          <Designation>{designation}</Designation>
        </CommentBoxContainer>
      </CommentsContainer>
    </MembersCardContainer>
  );
};

export default CommentBox;
