import styled from "styled-components";

export const RedButton = styled.button`
  color: #ffffff;
  padding: 15px 24px;
  background: #e25252;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  border: 0;
  max-width: fit-content;
  text-transform: uppercase;
  z-index: 1;
  @media (max-width: 778px) {
    padding: 10px 20px;
    font-size: 1.5vw;
  }
  @media (max-width: 700px) {
    font-size: 12px !important;
    padding: 10px 20px;
  }
`;
