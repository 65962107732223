import styled from "styled-components";

interface Interface {
  marginBottom: boolean;
}

const SectionTitle = styled.div<Interface>`
  background: #315cd5;
  width: 19%;
  padding: 0 3%;
  font-weight: bold;
  font-family: Oxygen;
  line-height: 4.5vw;
  white-space: nowrap;
  border-bottom-right-radius: 7vw;
  font-size: 45px;
  letter-spacing: 2px;
  @media (max-width: 700px) {
    width: 26%;
    line-height: 9vw;
    font-size: 4.5vw;
    letter-spacing: 0.5px;
  }
  @media (max-width: 1100px) {
    font-size: 40px;
  }
`;

export default SectionTitle;
