import { IFaq } from "types";

export const faqs: IFaq[] = [
  {
    question: "How does it work?",
    answer: `Apply4U can be divided into four steps.`,
    steps: [
      {
        heading: "On boarding, ",
        message: `Once you have joined the service, you will be requested to join our slack channel. A designated 
                candidate success manager ( lets us call him Sam)  will onboard you via slack. The on boarding process 
                will entail you providing us with your resume, cover letter and filling out few details commonly asked 
                by employers in their initial application forms.`,
      },
      {
        heading: "Tracking & Logistics, ",
        message: `The customer success manager, will create a shared email address and a google spreadsheet for you. 
                The shared email address will be used to apply for various job applications. Typically portals and employers 
                expect you to create an account in their applicant tracking systems. The spreadsheet on the other hand is 
                used to keep track of applications made and their current status.`,
      },
      {
        heading: "Applying, ",
        message: `In your trial phase customer success manager will select 20+ jobs for you and obtain your feedback. 
                This helps him understand your goals and objectives and align his strategy  for "Applying4U". Once he has 
                nailed down your strategy he will start spending approx 10-12 hours per week going to selected job portals 
                and applying for you.`,
      },
      {
        heading: "Fine tuning, ",
        message: `Market realty and people circumstances are very dynamic, therefore We are always looking for your 
                feedback to see if he needs to change your strategy or fine tune the approach.`,
      },
    ],
  },
  {
    question: "How long does it take to start seeing interviews lined up ?",
    answer: `Typically, it takes 2 weeks for you to start seeing positive responses from Sam's efforts. 
        This is due to just the sheer number of applications that our HR friends have to review and respond too. 
        Our process is guaranteed to produce results.`,
  },
  {
    question: "What are the job portals covered by Apply4u?",
    answer: `Some of the most prominent portals covered by Apply4u are glassdoor.com, 
        angel.com, indeed.com, and StackOverflow. The list gets added with more job portals 
        and sites as time goes by. In case the candidate has a particular site or portal 
        that we have overlooked, he is free to bring it up with the Sam. We would only be 
        glad to include it in his search for a placement.
        `,
  },
  {
    question: "What can I expect with the monthly subscription?",
    answer: `Typically we apply for around 100+ job applications per week. 
        Your dedicated customer success manager is always available to you via 
        Slack to answer any questions or change strategy and direction.`,
  },
  {
    question: "How does application tracking work ?",
    answer: `Once the application process is started for a particular aspirant, an excel sheet is 
        prepared with the different companies and designations that are being applied for
        on behalf of the applicant. The candidate is provided with a column to record his
        comments to any of the specific positions that has been applied for. These
        comments and statements are considered when Apply4u deals with the aspirant
        and job vacancies. The aim here is to create a job application that is in tune with 
        the aspirations and desires of the candidates. The comments in each case help further 
        improve on the application process thus getting to target positions across verticals better.
        `,
  },
  {
    question:
      "Can subscriptions be cancelled in the middle of the billing cycle?",
    answer: `Yes, you can cancel anytime and you will be refunded a pro-rata amount, 
        in which case the sum of money that has been calculated as being left over 
        subscription of the billing cycle is refunded back to the applicant.
        `,
  },
  {
    question: "How do I get notified about my interviews?",
    answer: `At the time of applying to a company on behalf of an aspirant, the 
        employer is provided with the personal email address of the applicant. All 
        correspondence with the potential employee are carried out through this 
        email address at all times, including intimating the status of the applications 
        and feedback if any.
        `,
  },
  {
    question: "How do I start/stop my service?",
    answer: `Inform your customer success manager, via an email or a slack message 
        and he should be able to start/stop your service. Typically, we see this happen 
        when you have too many interviews lined up or want to take a break from interviewing.`,
  },
  {
    question: "What is the success rate of this service?",
    answer: `We have a 100% success rate in lining up interviews. We have consistently 
        seen results right around the two week’s mark.`,
  },
  {
    question: "Can I talk to someone who has used the service?",
    answer: `Yes, please join our slack channel and we will connect you with our 
        existing or past customer, that aligns with your background.`,
  }
];
