import React from "react";
import { SectionTitle } from "../Common";
import styled from "styled-components";
import { Member } from "assets";
import { isMobile } from "react-device-detect";
import { CommentBox } from "../Common";

const MembersContainer = styled.div``;

const CommentsGroupContainer = styled.div`
  padding: 2rem;
  display: flex;
  ${isMobile && "flex-direction: column"}
`;

const Members = () => {
  return (
    <MembersContainer>
      <SectionTitle marginBottom={false}>What Our Members Say</SectionTitle>
      <CommentsGroupContainer>
        <CommentBox
          imageSource={Member}
          heading={`Here is my testimonial...`}
          message={`Apply4U did an excellent job of searching for jobs for me. I was in a unique situation 
           that required a lot of application submissions before I could find that perfect company 
           that also thought I was perfect for them as well. Apply4U took the drudgery out of doing 
           the hunting and applying task without blowing my budget. Ultimately I landed the best job 
           of my career as a result...much thanks for all of your hard work Apply4U.`}
          name={"Michael"}
          designation={"Anacortes, WA"}
        />
      </CommentsGroupContainer>
    </MembersContainer>
  );
};

export default Members;
