import React from "react";
import styled from "styled-components";
import { Logo, Facebook, Twitter, Instagram, Linkedin } from "assets";
import { isMobile } from "react-device-detect";

const SocialLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size: 1.2vw;
  margin-right: 10%;
  color: #ffffff;
  .feature-update {
    margin-top: 2%;
  }
  ${isMobile &&
  `
        width: 100%;
        .feature-update {
            font-size: 3vw;
        }
    `}
`;

const InterviewHelpLogo = styled.img`
  ${isMobile &&
  `
            width: 50%;
        `}
`;

const IconGroupContainer = styled.div`
  justify-content: space-between;
  max-width: 35%;
  display: flex;
  margin-top: 6%;
  ${isMobile &&
  `
        margin-top: 4%;
        max-width: 45%;
    `}
`;

const IconContainer = styled.div`
  display: flex;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-right: 6%;
  width: 100%;
  padding: 4px;
  cursor: pointer;
`;

const Image = styled.img`
  margin: auto;
  width: 50%;
  display: block;
`;

interface Iicon {
  imageSource: string;
  url: string;
}

const Icon = ({ imageSource, url }: Iicon) => {
  const onIconClick = () => {
    window.location.href = url;
  };

  return (
    <IconContainer onClick={onIconClick}>
      <Image src={imageSource} />
    </IconContainer>
  );
};

const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <div>
        <InterviewHelpLogo src={Logo} />
      </div>
      <div className={"feature-update"}>
        INTERVIEW HELP may, from time to time, make changes to the content and
        features of the Site
      </div>
      <IconGroupContainer>
        <Icon
          imageSource={Facebook}
          url={"https://www.facebook.com/interviewhelpio/"}
        />
        <Icon
          imageSource={Twitter}
          url={"https://twitter.com/techinterview2"}
        />
        <Icon
          imageSource={Instagram}
          url={`https://www.youtube.com/channel/UCmdo8WoDIwDM9BMhXs9sOrw`}
        />
        <Icon
          imageSource={Linkedin}
          url={`https://www.linkedin.com/company/interviewhelp-io`}
        />
      </IconGroupContainer>
    </SocialLinksContainer>
  );
};

export default SocialLinks;
