import "bootstrap/dist/css/bootstrap.min.css";
import { faqs } from "data";

import React from "react";
import styled from "styled-components";

import { AccordionGroup, SectionTitle } from "../Common";

const FAQContainer = styled.div`
  margin-bottom: 5%;
`;

const FAQ = () => {
  return (
    <FAQContainer id="faq">
      <SectionTitle marginBottom={false}>
        Frequently asked questions about Apply4U
      </SectionTitle>
      <AccordionGroup faqs={faqs} />
    </FAQContainer>
  );
};

export default FAQ;
