import { DotsGroup, GetStartedBottomImage, Meeting } from "assets";
import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";

import {
  Applications,
  Apply4UForm,
  FAQ,
  Footer,
  HowItWorks,
  Members,
} from "..";
import Apply4UHeader from "./Apply4UHeader";
import SignupHeader from "./SignupHeader";

const TopContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 885px;
  left: -1px;
  top: -5.7px;
  background: #315cd5;
  overflow: hidden;
  ::after {
    position: absolute;
    width: 100%;
    height: 285.86px;
    left: -1px;
    bottom: 0;
    content: url(${GetStartedBottomImage});
    display: block;
    background-image: linear-gradient(
      180deg,
      #315cd5 20%,
      #ffffff 40%,
      #ffffff 40%
    );
    @media (max-width: 700px) {
      position: relative;
      width: auto;
      height: auto;
    }
    @media (min-width: 2000px) {
      height: 350px;
    }
  }
  @media (max-width: 1400px) {
    height: 740px;
  }
  @media (max-width: 700px) {
    height: auto;
    position: inherit;
  }
`;

const MeetingIconContainer = styled(Col)`
  position: absolute;
  width: 784px;
  height: 580px;
  left: calc(100% - 55rem);
  top: 0;
  margin-top: 9rem;
  z-index: 1;
  filter: drop-shadow(30px 24px 45px rgba(0, 0, 0, 0.25));
  @media (max-width: 1400px) {
    width: 520px;
    height: 0;
    left: calc(100% - 37rem);
    filter: none;
  }
  @media (max-width: 700px) {
    display: none;
  }
  @media (max-width: 1100px) {
    display: none;
  }
`;

const MeetingIcon = styled.img`
  width: 100%;
`;

const DotsGroupIcon = styled.img`
  position: absolute;
  left: 87.19%;
  right: -19.93%;
  top: 25.69%;
  @media (max-width: 1400px) {
    height: 236px !important;
    top: 107px !important;
  }
`;

const Body = styled.div`
  position: absolute;
  top: 885px;
  @media (max-width: 1400px) {
    top: 740px;
    width: 100%;
  }
  @media (max-width: 700px) {
    position: inherit;
  }
`;

const GetStarted = () => {
  return (
    <div>
      <TopContainer>
        <MeetingIconContainer>
          <MeetingIcon src={Meeting} />
          <DotsGroupIcon src={DotsGroup} />
        </MeetingIconContainer>
        <SignupHeader />
        <Apply4UHeader />
      </TopContainer>
      <Body>
        <HowItWorks />
        <Applications />
        <Apply4UForm />
        <FAQ />
        <Members />
        <Footer />
      </Body>
    </div>
  );
};

export default GetStarted;
