import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

interface ILink {
  name: string;
  url: string;
  elementId?: string;
}

export interface InfoLinksInterface {
  heading: string;
  links: ILink[];
}

interface ILinksList {
  linksList: InfoLinksInterface[];
}

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 40%;
  .link-column {
    display: flex;
    flex-direction: column;
  }
  .heading {
    font-size: 1.2em;
    margin-bottom: 12%;
  }
  .link {
    font-size: 0.9em;
    cursor: pointer;
  }
  ${isMobile &&
  `
        width: 100%;
        margin-top: 2em;
        justify-content: flex-start;
        padding-left: 4%;
        .heading {
            font-size: 1em;
            margin-bottom: 4%;
        }
        .link {
            font-size: .8em;
        }
    `}
`;

const LinksColumn = ({ heading, links }: InfoLinksInterface) => {
  const onLinkClick = ({ elementId }: ILink) => {
    if (elementId) {
      const element = document.getElementById(elementId);
      element &&
        element.scrollIntoView({
          behavior: "smooth",
        });
    }
  };

  return (
    <div className={"link-column"}>
      <div className={"heading"}>{heading}</div>
      {links.map((link: ILink, index: number) => {
        return (
          <div key={index} onClick={() => onLinkClick(link)} className={"link"}>
            {link.name}
          </div>
        );
      })}
    </div>
  );
};

const InfoLinks = ({ linksList }: ILinksList) => {
  return (
    <LinksContainer>
      {linksList.map((links: InfoLinksInterface, index: number) => {
        return (
          <LinksColumn
            key={index}
            heading={links.heading}
            links={links.links}
          />
        );
      })}
    </LinksContainer>
  );
};
export default InfoLinks;
