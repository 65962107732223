import React from "react";
import { useContext } from "react";
import {
  Accordion,
  Card,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap";
import { IAccordion, IAccordionBody, IFaqStep } from "types";
import styled from "styled-components";

const StepContainer = styled.div`
  padding: 0.6rem;
  .step-row {
    display: flex;
    margin-bottom: 3px;
  }
  .step-count {
    margin-right: 10px;
  }
  .step-heading {
    font-weight: bold;
  }
  .step-message {
  }
`;

const AccordionHeader = ({ eventKey, callback, question }: any) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header className={"accordion-card-header"} onClick={decoratedOnClick}>
      {question}
      <div className={"accordion-button"}>{isCurrentEventKey ? "-" : "+"}</div>
    </Card.Header>
  );
};

const AccordionBody = ({ evenyKey, answer, steps }: IAccordionBody) => {
  return (
    <Accordion.Collapse eventKey={evenyKey}>
      <Card.Body className={"accordion-card-body"}>
        {answer}
        {steps && steps.length && (
          <StepContainer>
            {steps.map((step: IFaqStep, index: number) => {
              return (
                <div className={"step-row "} key={index}>
                  <div className={"step-count"}>{index + 1}.</div>
                  <div>
                    <span className={"step-heading"}>{step.heading}</span>
                    <span className={"step-message"}>{step.message}</span>
                  </div>
                </div>
              );
            })}
          </StepContainer>
        )}
      </Card.Body>
    </Accordion.Collapse>
  );
};

const AccordionCard = ({ evenyKey, question, answer, steps }: IAccordion) => {
  return (
    <Card className={"accordion-card"}>
      <AccordionHeader question={question} eventKey={evenyKey} />
      <AccordionBody evenyKey={evenyKey} answer={answer} steps={steps} />
    </Card>
  );
};

export default AccordionCard;
