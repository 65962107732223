import React from "react";
import styled from "styled-components";

const ImageCardContainer = styled.div`
  display: flex;
  padding: 1rem;
  background-color: #ffffff;
  margin-right: 3rem;
  box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.03);
  @media (max-width: 700px) {
    font-size: 2.8vw;
    margin: 0.5rem 1rem !important;
  }
`;

const Image = styled.img`
  height: 13vw;
  padding: 1.5rem;
  @media (max-width: 700px) {
    width: 50%;
    padding-right: 1rem;
    height: auto;
  }
`;

const TextContainer = styled.div`
  max-width: 17em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 880px) {
    max-width: auto !important;
  }
  @media (max-width: 700px) {
    font-size: 2.8vw;
    width: 50%;
    max-width: auto !important;
  }
  span.textGrey {
    color: #949393;
    font-size: 0.8em;
  }
`;

interface CardInterface {
  heading: string;
  message: string;
  imageSource: string;
}

const ImageCard = ({ heading, message, imageSource }: CardInterface) => {
  return (
    <ImageCardContainer>
      <Image src={imageSource} />
      <TextContainer>
        <span className={"h5"}>{heading}</span>
        <span className={"textGrey"}>{message}</span>
      </TextContainer>
    </ImageCardContainer>
  );
};

export default ImageCard;
