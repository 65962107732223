import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { planDetails } from "data";

const PricingContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  max-width: 25rem;
  ${isMobile &&
  `
  margin-left: 0 !important;
        width: 100%;
    `}
`;

const OurPricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #0e3cbe;
  color: #ffffff;
  padding: 1rem 2rem;
  width: 100%;
  text-align: center;
  .try-pricing {
    font-size: 0.8em;
  }
  span.price-currency {
    position: relative;
    bottom: 1.5em;
  }
  span.price-value {
    font-size: 3em;
  }
  ${isMobile &&
  `
        .our-pricing {
            font-size: 2.5em;
        }
        span.price-value {
            font-size: 4em;
        }
        span.price-currency {
            bottom: 1.6em;
            font-size: 1.3em;
        }
    `}
`;

const PlanDetailsContainer = styled.div`
  background: #ffffff;
  color: #545557;
  padding: 1.5rem;
  width: 100% !important;
  .plan-point {
    display: list-item;
    margin-left: 8%;
    font-size: 0.9em;
  }
`;

const OurPricing = () => {
  return (
    <OurPricingContainer>
      <div className={"h2"}>Our Pricing</div>
      <div className={"try-pricing"}>Try Apply4U for 7 days or Your Moneyback</div>
      <div>
        <span className={"price-currency"}>$</span>
        <span className={"price-value"}>300</span>
        <span>/Week</span>
      </div>
    </OurPricingContainer>
  );
};

const PlanDetails = () => {
  return (
    <PlanDetailsContainer>
      {planDetails.map((planPoint, index, planDetailsArr) => {
        return (
          <div
            key={index}
            className={`plan-point ${
              index !== planDetailsArr.length - 1 && "pb-3"
            }`}
          >
            {planPoint}
          </div>
        );
      })}
    </PlanDetailsContainer>
  );
};

const Pricing = () => {
  return (
    <PricingContainer>
      <OurPricing />
      <PlanDetails />
    </PricingContainer>
  );
};

export default Pricing;
