import React from "react";
import styled from "styled-components";
import FormFields from "./FormFields";
import Pricing from "./Pricing";
import { isMobile } from "react-device-detect";

const Apply4UContainer = styled.div`
  background-color: #ffffff;
  margin-bottom: 3rem;
  padding-right: ${isMobile ? "0" : "1rem"};
`;

const RightPointContainer = styled.div`
  display: flex;
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
  background: #315cd5;
  ${isMobile &&
  `
    clip-path: inherit;
    flex-direction: column;
  `}
`;

const Line = styled.div`
  ${isMobile
    ? `border-top: 1px solid #fafafa;`
    : `border-left: 1px solid #fafafa; height: 100%`}
`;

const Apply4UForm = () => {
  return (
    <Apply4UContainer id="apply4UGetStarted">
      <RightPointContainer className={!isMobile ? "row" : ""}>
        <div className={!isMobile ? "col-lg-7" : ""}>
          <FormFields />
        </div>
        <div>
          <Line />
        </div>
        <div className={!isMobile ? "col-lg-4 d-flex align-items-center" : ""}>
          <Pricing />
        </div>
      </RightPointContainer>
    </Apply4UContainer>
  );
};

export default Apply4UForm;
