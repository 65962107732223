import React from "react";
import styled from "styled-components";
import { FooterImage } from "assets";
import { isMobile } from "react-device-detect";
import SocialLinks from "./SocialLinks";
import InfoLinks, { InfoLinksInterface } from "./InfoLinks";
import Copyright from "./Copyright";

const FooterBackground = styled.div`
  background: #315cd5;
  color: #ffffff;
  ::before {
    content: url(${FooterImage});
    display: block;
    background-image: linear-gradient(0deg, #315cd5 50%, #ffffff 50%);
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4%;
  position: relative;
  bottom: 8vw;
  ${isMobile &&
  `
        flex-direction: column;
    `}
`;

const links: InfoLinksInterface[] = [
  {
    heading: "Information",
    links: [
      { name: "FAQs", url: "", elementId: "faq" },
      { name: "Member Help", url: "" },
      { name: "Contact Us", url: "" },
    ],
  },
];

const Footer = () => {
  return (
    <FooterBackground>
      <FooterContainer>
        <SocialLinks />
        <InfoLinks linksList={links} />
      </FooterContainer>
      <Copyright />
    </FooterBackground>
  );
};

export default Footer;
