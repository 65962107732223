import React from "react";
import styled from "styled-components";

import { Logo } from "assets";
// import { RedButton } from "./RedButton";

const SignupHeaderContainer = styled.div`
  display: flex;
  padding: 1% 3rem;
  justify-content: space-between;
  align-items: center;
  height: 120px;
`;

const LogoContainer = styled.img`
  width: 243px;
  height: 77px;
  margin-left: 2.5rem;
  @media (max-width: 700px) {
    width: 50%;
    margin-left: 0;
  }
`;

const InterviewHelpLogo = () => <LogoContainer src={Logo} />;

const SignupHeader = () => {
  return (
    <SignupHeaderContainer>
      <InterviewHelpLogo />
      {/* <RedButton>Signin / Signup</RedButton> */}
    </SignupHeaderContainer>
  );
};

export default SignupHeader;
