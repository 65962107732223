import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import {
  Connector1,
  Connector2,
  HowItWorksBottomImage,
  HowItWorksFlow1,
  HowItWorksFlow2,
  HowItWorksFlow3,
} from "assets";
import { SectionTitle, StepCircle } from "../Common";

const HowItWorksContainer = styled.div`
  margin-top: -1px !important;
  background: #fff;
  ::after {
    content: url(${HowItWorksBottomImage});
    background-image: linear-gradient(
      0deg,
      #fcfcfc 20%,
      #ffffff 40%,
      #ffffff 40%
    );
  }
  @media (max-width: 700px) {
    padding-top: 2rem;
  }
`;

const ImagesContainer = styled.div`
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }
`;

const Connector = styled.img`
  margin-bottom: 17em;
  width: 29%;
  margin-left: -6rem;
  margin-right: -6rem;
  @media (max-width: 700px) {
    display: none;
  }
`;

const ImageVerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
  @media (max-width: 700px) {
    width: 60%;
    margin-top: 2em !important;
    align-items: center;
  }
  &.two {
    margin-top: 10%;
  }
`;

const ButtonTextContainer = styled.div`
  text-align: center;
  span.textBold {
    font-weight: bold;
  }
  @media (max-width: 700px) {
    font-size: 2.8vw;
  }
`;

const Img = styled.img`
  @media (max-width: 700px) {
    padding: 0 !important;
  }
`;

const StyledP = styled.p`
  font-size: 1.1rem;
  &::first-line {
    font-weight: 700;
    font-size: 1.6rem;
  }
  ${isMobile &&
  `
    font-size: 12px;
    &::first-line {
      font-weight: 700;
      font-size: 24px;
    }
  `}
`;

const HowItWorks = () => {
  return (
    <HowItWorksContainer>
      <SectionTitle marginBottom={false}>How it works</SectionTitle>
      <ImagesContainer className="mt-5">
        <ImageVerticalContainer>
          <div className="d-flex align-items-center">
            <StepCircle className="position-absolute">{"01"}</StepCircle>
            <Img src={HowItWorksFlow1} alt="" />
          </div>
          <BottomText value={1} />
        </ImageVerticalContainer>
        <Connector src={Connector1}></Connector>
        <ImageVerticalContainer className={"two"}>
          <div className="d-flex align-items-center">
            <StepCircle className="position-absolute">{"02"}</StepCircle>
            <Img src={HowItWorksFlow2} alt="" />
          </div>
          <BottomText value={2} />
        </ImageVerticalContainer>
        <Connector src={Connector2}></Connector>
        <ImageVerticalContainer>
          <div className="d-flex align-items-center">
            <StepCircle className="position-absolute">{"03"}</StepCircle>
            <Img src={HowItWorksFlow3} alt="" />
          </div>
          <BottomText value={3} />
        </ImageVerticalContainer>
      </ImagesContainer>
    </HowItWorksContainer>
  );
};

interface TextValue {
  value: number;
}

const BottomText = ({ value }: TextValue) => {
  return (
    <ButtonTextContainer className={`text${value}`}>
      <StyledP className={"textBold"}>{Texts[`Text${value}`]}</StyledP>
    </ButtonTextContainer>
  );
};

const Texts: any = {
  Text1: "Provide Apply4U with your job application information once.",
  Text2:
    "Apply4U will search and apply to job applications with your information for you.",
  Text3: "You will start getting confirmations while you sit back and relax.",
};

export default HowItWorks;
