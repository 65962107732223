import * as React from "react";
import styled from "styled-components";

const ErrorMessage = styled.p`
  text-align: left;
  font-size: 13px;
  margin-bottom: 0;
  text-align: left;
  color: #ffff00;
  margin-top: 2px;
`;

type ErrorType = {
  errorMessage: string | undefined;
};

export const Error = ({ errorMessage }: ErrorType) => (
  <ErrorMessage>{errorMessage}</ErrorMessage>
);
