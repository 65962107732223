import "./App.css";

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Home } from "./components";

function App() {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;
