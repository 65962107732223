import React from "react";
import GoogleFontLoader from "react-google-font-loader";
import styled from "styled-components";

import {
  Applications,
  Apply4UForm,
  FAQ,
  GetStarted,
  HowItWorks,
  Members,
  Footer,
} from "..";

const Root = styled.div`
  font-family: Open Sans;
`;

const Home = () => {
  return (
    <div>
      <GoogleFontLoader
        fonts={[
          { font: "Oxygen", weights: [400, "400i"] },
          { font: "Open Sans", weights: [400, 200] },
          { font: "Poppins", weights: [300] },
        ]}
        subsets={[]}
      />
      <Root>
        <GetStarted />
      </Root>
    </div>
  );
};

export default Home;
