import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

import { RedButton } from "./RedButton";

const Apply4UHeaderContainer = styled(Row)`
  display: flex;
  justify-content: space-between;
  padding-left: 3rem;
  height: calc(100% - 120px);
  padding-top: 3rem;
  @media (max-width: 700px) {
    padding: 0 3rem;
    margin-bottom: 0;
    height: auto;
  }
`;

const Apply4UVerticalContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  justify-content: flex-start;
  @media (max-width: 700px) {
    margin-left: 0rem;
  }
`;

const JobApplicationTextContainer = styled.div`
  color: #ffffff;
  font-weight: 300;
  font-size: 27px;
  margin-bottom: 5%;
  letter-spacing: 0.6px;
  @media (max-width: 1400px) {
    font-size: 20px !important;
  }
  @media (max-width: 700px) {
    font-size: 12px !important;
    line-height: 2 !important;
    margin-bottom: 8% !important;
  }
`;

const JobApplicationText = () => {
  return (
    <JobApplicationTextContainer>
      <span>
        You answer one set of questions one time
        <br />
        We take care of job applications every time
      </span>
    </JobApplicationTextContainer>
  );
};

const Apply4UText = styled.div`
  color: #ffffff;
  font-weight: bold;
  font-size: 73px;
  margin-bottom: 2%;
  width: fit-content;
  @media (max-width: 1400px) {
    font-size: 53px !important;
  }
  @media (max-width: 700px) {
    font-size: 7vw !important;
    margin-bottom: 4%;
  }
`;

const Apply4UHeader = () => {
  const onGetStartedClick = useCallback(() => {
    const element = document.getElementById("apply4UGetStarted");
    element &&
      element.scrollIntoView({
        behavior: "smooth",
      });
  }, []);

  return (
    <Apply4UHeaderContainer>
      <Apply4UVerticalContainer>
        <div>
          <Apply4UText>Apply4U</Apply4UText>
          <JobApplicationText />
        </div>
        <RedButton onClick={onGetStartedClick}>Get Started</RedButton>
      </Apply4UVerticalContainer>
    </Apply4UHeaderContainer>
  );
};

export default Apply4UHeader;
