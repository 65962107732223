import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IApply4UForm } from "types";
import { Error } from "components/Common/Error";
import createUser, { userInfo } from "api/users";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding: 4%;
  .apply-for-easy {
    font-size: 2.7vw;
    font-weight: bold;
    font-family: Oxygen;
    margin-bottom: 2%;
  }
  .apply-trial {
    font-size: 1.4vw;
  }
  .input-form {
    background: #315cd5;
    color: #ffffff;
    border: 1px solid #ffffff;
    padding: 0.7vw;
    font-size: 2vw;
    font: initial;
    margin: 3% 0 0;
    ::placeholder {
      color: #ffffff;
      opacity: 0.7;
      font-size: 0.8rem;
    }
    :focus {
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
      outline: 0px;
    }
  }
  .input-submit {
    margin: 4% 0%;
    border-color: transparent;
    padding: 0.5rem 1.7rem !important;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 0.8vw;
    color: #000000;
    text-transform: uppercase;
    font-size: 14px;
  }
  p.user-success {
    color: greenyellow;
    .thanks-note {
      font-weight: 700;
      font-size: 1.2rem;
    }
    .redirect-note {
      font-size: 0.9rem;
    }
    .redirect-count {
      margin-top: 1rem;
      color: #ffffff;
    }
  }
  ${isMobile &&
  `
    padding: 2rem;
    .apply-for-easy {
      font-size: 1.4em;
    }
    .apply-trial { 
      font-size: .8em;
    }
    .input-submit {
      padding: 0.5rem !important;
    }
  `}
`;

const formSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
});

type InputType = {
  field: string;
  type: string;
};

const formControlFields: InputType[] = [
  { field: "name", type: "text" },
  { field: "email", type: "email" },
  { field: "phone", type: "text" },
];

type FormControlType = {
  register: any;
  errors: any;
};

const FormControls = ({ register, errors }: FormControlType) => {
  return (
    <>
      {formControlFields.map(({ field, type }: InputType) => {
        return (
          <Form.Group key={field} controlId={field}>
            <Form.Control
              {...register(field)}
              style={{ width: !isMobile ? "50%" : "100%" }}
              className={"input-form pl-3"}
              type={type}
              placeholder={field.toUpperCase()}
            />
            {errors && errors[field] && (
              <Error errorMessage={errors[field].message} />
            )}
          </Form.Group>
        );
      })}
    </>
  );
};

const FormFields = () => {
  const [userCreated, setUserCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectCountDown, setRedirectCountDown] = useState(0);

  useEffect(() => {
    if (redirectCountDown === 0) {
      if (userCreated) {
        redirectToSlack();
      }
      return;
    }

    const intervalId = setInterval(() => {
      setRedirectCountDown(redirectCountDown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [redirectCountDown]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IApply4UForm>({
    resolver: yupResolver(formSchema),
    mode: "onChange",
    shouldFocusError: true,
  });

  const handleFormSubmit = (data: userInfo) => {
    setIsLoading(true);
    createUser(data)
      .then(() => {
        setIsLoading(false);
        setUserCreated(true);
        setRedirectCountDown(5);
      })
      .catch(() => {
        setIsLoading(false);
        setUserCreated(false);
      });
  };

  const redirectToSlack = () => {
    window.location.href = "http://joinslack.apply4u.io/";
  };

  return (
    <FormContainer className="ml-4">
      <div className="mb-3">
        <div className={"h1 font-weight-bold"}>Apply4U Is Easy!</div>
      </div>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControls register={register} errors={errors} />
        <Button
          className={"input-submit mt-2 p-1"}
          variant="primary"
          type="submit"
          disabled={isLoading || userCreated}
        >
          Submit
        </Button>
      </Form>
      {isLoading && <Spinner animation="border" role="status" />}
      {userCreated && (
        <p className={"user-success"}>
          <div className={"thanks-note"}>Thanks for signing up</div>
          <div className={"redirect-note"}>
            you will be directed to join our slack channel and a customer
            success manager will onboard you
          </div>
          <div className={"redirect-count"}>
            You should be automatically redirected in{" "}
            <span style={{ fontWeight: "bold" }}>{redirectCountDown}</span>{" "}
            seconds
          </div>
        </p>
      )}
    </FormContainer>
  );
};

export default FormFields;
