import styled from "styled-components";

const StepCircle = styled.div`
  width: 55px;
  height: 55px;
  background: rgba(49, 92, 213, 0.5);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 13%);
  font-weight: bold;
  font-size: 28px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
`;

export default StepCircle;
