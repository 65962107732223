import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

(window as any).renderApply4U = (containerId: string) => {
  ReactDOM.render(<App />, document.getElementById(containerId));
  serviceWorker.unregister();
};

(window as any).unmountApply4U = (containerId: string) => {
  const node = document.getElementById(containerId);
  node && ReactDOM.unmountComponentAtNode(node);
};

if (!document.getElementById("Apply4U-container")) {
  ReactDOM.render(<App />, document.getElementById("apply-for-u-root"));
  serviceWorker.unregister();
}
