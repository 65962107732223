import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { Accordion } from "react-bootstrap";
import styled from "styled-components";
import AccordionCard from "./AccordionCard";
import { isMobile } from "react-device-detect";
import { IFaq, IAccordionData } from "types";

const AccordionGroupContainer = styled.div`
  .accordion-card {
    margin: 2% 4%;
    background: #fafafa;
    border: none;
  }
  .accordion-card-header {
    background: #fafafa;
    border-bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-size: 1.7vw;
  }
  .accordion-card-body {
    padding-top: 0;
    font-size: 1.2vw;
    ::before {
      content: "";
      border-bottom: 1px solid grey;
      display: block;
      margin-bottom: 0.75rem;
    }
  }
  .accordion-button {
    border: none;
    background: #fafafa;
    padding: 0 10px;
    position: relative;
    font-size: 3vw;
    height: 0;
    bottom: 1.2vw;
  }
  ${isMobile &&
  `
    .accordion-card-header {
      font-size: 1rem;
    }
    .accordion-card-body {
      font-size: .8rem;
    }
  `}
`;

const AccordionGroup = ({ faqs }: IAccordionData) => {
  return (
    <AccordionGroupContainer>
      <Accordion defaultActiveKey="0">
        {faqs.map((faqObj: IFaq, index: number) => {
          return (
            <AccordionCard
              key={`${index}`}
              evenyKey={`${index}`}
              question={faqObj.question}
              answer={faqObj.answer}
              steps={faqObj.steps}
            />
          );
        })}
      </Accordion>
    </AccordionGroupContainer>
  );
};

export default AccordionGroup;
