import axios, { AxiosError, AxiosResponse } from "axios";

const url =
  process.env.REACT_APP_USERS_API_URL ||
  "https://api.interviewhelp.io/drip/users";

export type userInfo = {
  email: string;
  name: string;
  phone: string;
};

const createUser = (data: userInfo) => {
  console.log(process.env.REACT_APP_USERS_API_URL);
  return axios.post(url, transformRequest(data));
};

const transformRequest = ({ name, email, phone }: userInfo) => {
  return {
    first_name: name.split(" ")[0],
    last_name: name.split(" ")[1] || "",
    email: email,
    phone: phone,
    tags: "Apply4u_Initial_Interest",
  };
};

export default createUser;
