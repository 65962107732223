import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const CopyrightContainer = styled.div`
  padding: 0 4% 4%;
  ::before {
    content: "";
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: block;
    margin-bottom: 0.75rem;
  }
`;

const CopyrightText = styled.div`
  display: flex;
  justify-content: center;
  ${isMobile &&
  `
        font-size: .6em;
    `}
`;

const Copyright = () => {
  return (
    <CopyrightContainer>
      <CopyrightText>©2021 All Rights Reserved</CopyrightText>
    </CopyrightContainer>
  );
};

export default Copyright;
